import axios from 'axios';

export default class AdminAPI {
  ip= '';

  countFrom = 0;

  countTo = 49;

  constructor(ip = '') {
    if (ip !== '') {
      this.ip = ip;
    }
  }

  getGroupList() {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/superadmin/groupslist/getlists?fcount=${this.countFrom}&tcount=${this.countTo}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  getTokenList() {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/superadmin/tokens/get`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  getBlackList(key) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/superadmin/getbl?key=${key}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  addGroupToBlackList(groupName, key) {
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/superadmin/addbl?key=${key}&group=${groupName}`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }

  dellGroupFromBlackList(groups, key) {
    const resultRequest = groups.join(';');
    return new Promise((res, rej) => {
      axios.get(`${this.ip}/superadmin/delbl?key=${key}&group=${resultRequest};`).then((responce) => {
        res(responce.data);
      }).catch((err) => {
        rej(err);
      });
    });
  }
}
