<template>
  <div class="table" :draggable="false">
    <div ref="table-header" class="table__header">
      <div class="table__title cell-settings__numbers">
        <img src="/icons/table/hash.svg" class="table__img hash-img" />
      </div>

      <div class="table__title cell-settings__public">
        <search-user v-model:searchText="calcSearchText" class="table__search"></search-user>
      </div>

      <div class="table__title cell-settings__subscribes" @click.prevent="sort(1, $event)">
        Подписчики
        <img src="/icons/common/vector-arrow.svg" class="table__img" />
      </div>

      <div class="table__title cell-settings__coverage" @click.prevent="sort(2, $event)">
        Охват
        <img src="/icons/common/vector-arrow.svg" class="table__img" />
      </div>

      <div class="table__title cell-settings__auditory" @click.prevent="sort(3, $event)">
        Посетители
        <img src="/icons/common/vector-arrow.svg" class="table__img" />
      </div>

      <div class="table__title cell-settings__grow" @click.prevent="sort(4, $event)">
        Прирост
        <img ref="img-show" src="/icons/common/vector-arrow.svg" class="table__img img-show" />
      </div>

      <div :style="{ opacity: 1 }" class="table__title cell-settings__eye">
        <img src="/icons/table/eye.svg" class="table__icon-eye" />
      </div>
    </div>

    <!-- <pagination
      ref="pagination"
      class="table__caption"
      :tableName="tableName"
      :target="target"
      :indexPosition="indexPosition"
      @updatedTable="updatedTable"
    ></pagination> -->

    <circle-c v-if="checkAnimation" class="animation-container__icon"></circle-c>

    <div v-else class="table__body" :draggable="false">
      <div
        v-for="(item, index) in normalizeJSON"
        :key="item.id"
        class="table__row"
        :draggable="false"
        :class="{ select: isSelectClass(item.id) }"
      >
        <div class="table__title cell-settings__numbers">
          <span v-if="!isSelectShow" class="cell-settings__index">{{
            indexPosition + index + (indexPosition === 0? 1 : 0)
          }}</span>
          <check-box
            v-else-if="tableName !== 'Мои группы'"
            class="table__checkbox"
            @update:startState="updateCheckBoxes(item)"
          ></check-box>

          <!-- <span
            class="cell-settings__sell"
            :style="{ visibility: isSelectShow ? 'visible' : '' }"
            v-if="tableName === 'Мои группы'"
            @click="groupStateUpdate(item)"
          >
            <img
              v-if="!item.isSale"
              :ref="'icon' + (indexPosition + index + 1)"
              src="/icons/common/sale.svg"
              class="cell-settings__icon"
            />

            <img
              v-else
              :ref="'icon' + (indexPosition + index + 1)"
              src="/icons/common/no-sale.svg"
              class="cell-settings__icon"
            />
          </span> -->

          <span v-if="!isSelectShow" class="cell-settings__sell">{{
            indexPosition + index + 1
          }}</span>
        </div>

        <div :draggable="false" class="table__cell cell-settings__public">
          <a :href="item.group_link" :draggable="false" target="_blank" class="link">
            <div class="table__image-size">
              <img :src="item.photo_link" class="table__icon" />
            </div>
            <div class="display-panel">
              <div class="pannel-menu panel_align-top">
                <img
                  src="/icons/table/ok.svg"
                  v-if="Number(item.verified) === 1"
                  class="panel__image"
                />

                <img
                  src="/icons/table/lock.svg"
                  v-if="Number(item.closed) === 1"
                  class="panel__image"
                />
              </div>

              <div
                class="panel"
                :style="{
                  width: calcWidth(item),
                }"
              >
                <p class="panel__text" :class="{ panel__inf: item.type !== 'page' }">
                  {{ item.group_name }}
                </p>

                <p class="panel__toltip">
                  {{ item.group_name }}
                </p>

                <p class="panel__info" v-if="item.type === 'page'">
                  {{ item?.activity || "" }}
                </p>
              </div>
            </div>
          </a>
        </div>

        <div class="table__cell cell-settings__subscribes text__bold">
          {{ item.subscrices }}
        </div>

        <div class="table__cell cell-settings__coverage text__bold text__color_link">
          <a target="_blank" :href="item.cov_stat_link" class="link-size">
            <span class="text__main" v-if="checkParam(item.reach_reach) !== ''">
              {{ item.reach_reach === 0 ? "-" : item.coverage }}
            </span>

            <span class="text__sub" v-if="checkParam(item.reach_reach) !== ''">
              {{ item.reach_reach === 0 ? "" : item.covpercent }}
            </span>

            <img v-else src="/icons/table/lock.svg" class="image-size" />
          </a>
        </div>

        <div class="table__cell cell-settings__auditory text__bold text__color_link">
          <a target="_blank" class="link-size" :href="item.vis_stat_link">
            <span v-if="checkParam(item.visitors_visitors) !== ''">
              {{ item.visitors_visitors === 0 ? "-" : item.visitors }}
            </span>

            <img v-else src="/icons/table/lock.svg" class="image-size" />
          </a>
        </div>
        <div
          class="table__cell cell-settings__grow text__bold text__color_green"
          :class="{
            text__color_red: +item.grow_count < 0,
          }"
        >
          <arrow-icon :arrow="setArrow(item)" class="arrow"> </arrow-icon>

          <p class="text__main">{{ item.grow_count !== 0.1 ? item.grow : "-" }}</p>
        </div>

        <div
          v-if="item.eye_flag"
          class="table__title cell-settings__eye"
          @click="toogleInfoPage(item)"
        >
          <img v-if="item.eye_flag" src="/icons/table/eye.svg" class="table__icon-eye" />
          <span v-else class="table__icon-eye"></span>
        </div>
        <div v-else class="cell-settings__eye"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Pagination from '@/components/admin-page/DataUserPaginationTest.vue';
import CircleC from '@/components/atom/loader/Circle.vue';
import ArrowIcon from '@/components/atom/html-icon/arrow/ArrowIcon.vue';
import SearchUser from '@/components/common/BaseSearch.vue';
import CheckBox from '@/components/atom/BaseCheckBox.vue';

let debounceCaller;

export default {
  components: {
    // Pagination,
    CircleC,
    ArrowIcon,
    SearchUser,
    CheckBox,
  },
  props: {
    tableName: {
      type: String,
      default: '',
    },
    table: {
      type: Array,
      default() {
        return [];
      },
    },
    indexPosition: {
      type: Number,
      default: 0,
    },
    isSelectShow: {
      type: Boolean,
      default: false,
    },
    showAnimation: {
      type: Boolean,
      default: true,
    },
    isActionByUpdate: {
      type: Boolean,
      default: false,
    },
    isMainGroup: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['classInstanceList'],
  expose: ['getCheckBoxes', 'updatedTable'],
  emits: ['toogleInfoPage', 'sellGroup'],
  data() {
    return {
      localTable: [],
      enableEye: false,
      // test
      loadCount: false,
      show: false,
      addClassToPagination: false,
      delbuf: '',
      groupInfo: {},

      searchText: '',
      rotate: 0,
      listShow: null,
      selectedRows: [],
      showCatalogs: true,
      options: {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
      target: null,

      // isOpenGroupInformation: false,
      // hash map
      names: {
        Подписчики: 'sub',
        Посетители: 'vis',
        Прирост: 'grow',
        Охват: 'cov',
      },

      checkBoxes: [],
      paginationUpdateAnimation: false,
      currentClass: {},

      userId: '',

      publicSale: {},
      isLoadGroups: false,
      saleGroups: [],
    };
  },
  computed: {
    ...mapGetters('userPanel', ['getGroupList']),
    calcSearchText: {
      get() {
        return this.searchText;
      },
      set(value) {
        debounceCaller();
        this.searchText = value;
      },
    },

    normalizeJSON() {
      const newJSON = this.localTable;
      if (newJSON === undefined || newJSON === null) {
        return [];
      }
      newJSON.forEach((element, index) => {
        newJSON[index].subscrices = new Intl.NumberFormat('ru-RU').format(+element.sub_count);
        newJSON[index].coverage = new Intl.NumberFormat('ru-RU').format(+element.reach_reach);

        const cov = +element.reach_reach / +element.sub_count;
        const percent = (Number(cov.toFixed(2)) * 100).toFixed();
        newJSON[index].covpercent = `${percent}%`;

        newJSON[index].visitors = new Intl.NumberFormat('ru-RU').format(+element.visitors_visitors);
        newJSON[index].grow = new Intl.NumberFormat('ru-RU').format(+element.grow_count);
        newJSON[index].isSale = this.findSaledGroup(element.id);
      });

      return newJSON;
    },

    getGroupInfo() {
      if (!this.groupInfo) {
        return {};
      }

      return this.groupInfo;
    },

    checkAnimation() {
      // if (this.isMainGroup) {
      //   return (
      //     (this.showAnimation && this.isLoadGroups)
      //     || (this.paginationUpdateAnimation && this.isLoadGroups)
      //   );
      // }
      return this.showAnimation || this.paginationUpdateAnimation;
    },
  },
  watch: {
    table() {
      this.localTable = this.table;
    },
  },
  methods: {
    ...mapActions('pagination', ['deleteInstance']),
    findSaledGroup(currId) {
      const id = this.saleGroups.find((el) => el.id === currId);
      return !!id;
    },
    addFavorites(iconRef, item) {
      if (!this.isAuthenticated) {
        return;
      }

      const { id: gid } = item;
      // title
      this.$refs[iconRef].setAttribute('src', '/icons/common/yellow-star.svg');
      this.$store
        .dispatch('userPanel/addGroup', gid)
        .then(() => {
        })
        .catch(() => {
          console.log(
            'cannot add group',
            this.$refs[iconRef].setAttribute('src', '/icons/common/star.svg'),
          );
        });
    },

    updateCheckBoxes(item) {
      let findEl = -1;
      this.checkBoxes.forEach((el, index) => {
        if (el.id === item.id) {
          findEl = index;
        }
      });

      if (findEl === -1) {
        this.checkBoxes.push(item);
        return;
      }

      this.checkBoxes.splice(findEl, 1);
    },

    groupStateUpdate(item) {
      if (!item.isSale && this.isActionByUpdate) {
        this.$emit('sellGroup', item);
      } else {
        this.$emit('dellGroup', item);
      }
    },

    updatedTable(event, resp = null) {
      if (resp !== null) {
        this.localTable = resp;
      }

      this.paginationUpdateAnimation = event;
      // this.$emit('updatedTable');
    },

    getCheckBoxes() {
      return this.checkBoxes;
    },

    sort(index, event) {
      // let filtredParametr = '';
      let sortBy = true;
      const element = event.target.querySelector('.table__img')
        ? event.target.querySelector('.table__img')
        : event.target;

      switch (true) {
        case this.listShow === null:
          this.listShow = element;
          this.listShow.classList.add('img-show');
          // filtredParametr = this.names[event.target.innerText];
          break;
        case this.listShow !== element:
          this.listShow.classList.remove('img-show');
          this.listShow.style.transform = 'rotate(0deg)';
          this.listShow = element;
          this.listShow.classList.add('img-show');
          this.rotate = 0;
          // filtredParametr = this.names[event.target.innerText];
          break;
        default:
          this.rotate = 180 - this.rotate;
          sortBy = this.rotate === 0;

          this.listShow.style.transform = `rotate(${this.rotate}deg)`;
      }

      let currentValue = '';

      switch (+index) {
        case 1:
          currentValue = 'sub';
          break;
        case 2:
          currentValue = 'cov';
          break;
        case 3:
          currentValue = 'vis';
          break;
        case 4:
          currentValue = 'grow';
          break;
        default:
      }

      const filtredParametr = currentValue;
      const parametrs = {
        filtredParametr,
        sortBy,
      };

      const currentIndex = this.findGroupByName();
      if (+currentIndex !== -1) {
        this.getGroupList[currentIndex].showAnimation = true;
      }

      this.currentClass.getGroupsJSONBySort(parametrs).then((resp) => {
        if (+currentIndex !== -1) {
          this.getGroupList[currentIndex].table = resp;
          this.getGroupList[currentIndex].showAnimation = false;
        }
        this.$emit('updateTable', { value: this.indexPosition, resp });
      });
    },

    toogleInfoPage(groupInfo = {}) {
      if (groupInfo.eye_flag === false) {
        return;
      }
      this.$emit('toogleInfoPage', {
        groupInfo,
        isShow: true,
        tableName: this.tableName,
        groupListPosition: this.indexPosition,
      });
    },

    findGroupByName() {
      if (!this.getGroupList.length) {
        return -1;
      }
      return this.getGroupList.findIndex((el) => el.list_name === this.tableName);
    },

    calcWidth(item) {
      // console.log(item);
      const width = +item.verified === 1 || +item.closed === 1 ? 20 : 0;
      return `calc(100% - ${width}px)`;
    },

    // requests methods
    searchRequest() {
      const currentIndex = this.findGroupByName();
      if (+currentIndex !== -1) {
        this.getGroupList[currentIndex].showAnimation = true;
      }

      this.currentClass
        .getGroupsJSONBySearch(this.searchText)
        .then((resp) => {
          if (+currentIndex !== -1) {
            this.getGroupList[currentIndex].table = resp;
            this.getGroupList[currentIndex].showAnimation = false;
          }
        })
        .catch(() => {
          this.getGroupList[currentIndex].showAnimation = false;
        });
    },
    // not requests

    setArrow(item) {
      // if (item.grow_proc === 'none') {
      //   return 'arrow-up';
      // }
      return +item.grow_count >= 0 ? 'arrow-up' : 'arrow-down';
    },

    // reset auditory in slider component
    resetAuditory() {
      this.$refs.slider.resetAuditory();
    },
    // dont need now
    isSelectClass(currentValue) {
      return currentValue % 50 === 0;
    },

    updateItem(currentId, groupName) {
      this[groupName].forEach((element, index) => {
        if (element.id === currentId) {
          this[groupName][index].checked = true;

          this.callFromFilterRequest(groupName, element.settingName.toLowerCase());
          return;
        }
        this[groupName][index].checked = false;
      });
    },
    updateCheckBox(button) {
      if (button.name === 'verification') {
        this.requestByVerification(button.isChecked);
        this.resetInput();
      }
    },

    calcNumberGro(item, forSub = false) {
      if (forSub) {
        if (item.grow_proc === 'none') {
          return 0.0;
        }
        return +item.grow_proc > 0 && +Number.parseFloat(item.grow_proc).toFixed(2) === 0
          ? `~${+Number.parseFloat(item.grow_proc).toFixed(2)}`
          : `${+Number.parseFloat(item.grow_proc).toFixed(2)}`;
      }

      if (item.grow_proc === 'none') {
        return 0;
      }
      return new Intl.NumberFormat('ru-RU').format(
        Math.round(item.grow_proc * item.sub_count * 0.01),
      );
    },

    checkParam(item) {
      return +item === 0.1 || +item === 0.2 || +item === 0.3 ? '' : item;
    },

    debounce(f, t) {
      return () => {
        const previousCall = this.lastCall;
        this.lastCall = Date.now();
        if (previousCall && this.lastCall - previousCall <= t) {
          clearTimeout(this.lastCallTimer);
        }
        this.lastCallTimer = setTimeout(() => f(), t);
      };
    },
  },
  async mounted() {
    this.localTable = this.table;
    this.target = this.$refs['table-header'];
    this.currentClass = this.classInstanceList.value[this.tableName];

    this.listShow = this.$refs['img-show'];
    debounceCaller = this.debounce(this.searchRequest, 1000);
  },
  beforeUnmount() {
    this.deleteInstance(this.tableName);
  },
};
</script>

<style lang="scss" scoped>
.communites {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;

  width: 100%;
}

.contenter {
  width: calc(100% - 350px);
}

.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  // .table__caption
  &__caption {
    display: flex;
    justify-content: flex-end;
    height: 30px;

    margin: 0 5px 0 0;

    position: sticky;
    top: 0;
    width: fit-content;
    align-self: flex-end;
  }

  &__checkbox {
    width: 20px;
    height: 20px;
  }

  &__search {
    width: 90%;
    height: 35px;

    font-size: 18px;
    padding: 0 5px 5px;

    &::placeholder {
      font-size: 18px;
      line-height: 32px;
    }
  }

  &__image-size {
    width: 50px;
    height: 50px;

    margin: 0 5px 0 0;
  }

  &__icon {
    width: 50px;
    height: 50px;
  }

  &__icon-eye {
    width: 25px;
    height: 25px;
  }

  &__arrow {
    transform: rotate(-180deg);
  }

  &__img {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 0 5px 5px;
    opacity: 0;
  }

  // .table__header
  &__header {
    display: flex;
    background: #dee5eb;

    padding: 0 5px;
  }

  // .table__title
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;

    cursor: pointer;
    color: #45688e;
    // background: #fff;
  }

  // .table__body
  &__body {
    display: flex;
    flex-direction: column;

    border-top: 1px solid #dee5eb;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid #dee5eb;

    padding: 0 8px;
    background: #fff;

    &:hover .cell-settings__eye {
      opacity: 1;
    }

    @media (hover:hover) {
      &:hover {
      .cell-settings__sell {
        visibility: visible;
      }
      .cell-settings__index {
        visibility: hidden;
      }
     }
    }
  }

  // .table__cell
  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    padding: 5px 0 5px;
    margin: 1px 0;

    background: #fff;
  }
}

.cell-settings {
  &__numbers {
    position: relative;
    min-width: 50px;
    width: 50px;
    margin: 0 15px 0 0;
  }

  &__index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    visibility: visible;
  }

  &__public {
    min-width: 250px;
    width: 40%;
    justify-content: flex-start;
  }

  &__coverage {
    min-width: 120px;
    width: calc((100% - 40% - 50px - 60px) / 4);
  }

  &__subscribes {
    min-width: 120px;
    width: calc((100% - 40% - 50px - 60px) / 4);
  }

  &__auditory {
    min-width: 120px;
    width: calc((100% - 40% - 50px - 60px) / 4);
  }

  &__grow {
    min-width: 120px;
    width: calc((100% - 40% - 50px - 60px) / 4);
    cursor: default;
  }

  &__eye {
    min-width: 60px;
    width: 60px;
    opacity: 1;
  }

  &__sell {
    visibility: hidden;
    max-width: 40px;
    max-height: 40px;

    position: absolute;
    width: 100%;
    height: 100%;

    &:hover {
      visibility: visible;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
  }
}

.image-size {
  width: 15px;
  height: 15px;
  // margin: 0 0 0 10%;
}

.link {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  position: relative;

  text-decoration: none;

  &:hover .panel__text {
    text-decoration: underline;
  }
}

.img-show {
  opacity: 1;
}

.display-panel {
  display: flex;
  justify-content: flex-start;

  align-items: stretch;
  height: 100%;
  // flex-basis: 80%;
  // width: 100%;

  width: calc(100% - 50px);
}

.pannel-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 3px;
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 3px;

  &_align-top {
    justify-content: flex-start;
  }
  &__image {
    width: 20px;
    height: 20px;
  }

  // .panel__text
  &__text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-overflow: ellipsis;
    text-align: left;
    display: block;
    margin: 5px 0;

    font-size: 20px;

    // &:hover + .panel__toltip {
    //   display: block;
    // }
  }

  //  &__toltip {
  //   display: none;
  //   position: absolute;
  //   top: 0;
  //   left: 100%;
  //   background: #fff;
  //   border: 1px solid;
  //   width: 330px;
  //   z-index: 10;
  //   padding: 0px 10px;
  //   box-sizing: border-box;
  //   height: 70px;
  //   overflow: hidden;
  //   text-align: left;

  //   &:hover {
  //     display: block;
  //   }

  //}

  // .panel__info
  &__info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    height: 10px;
    color: #c5c5c5;
    font-size: 11px;
    text-decoration: none;
  }

  &__inf {
    margin: -4px 0px;
  }

  // .panel__tags
  &__tags {
    font-size: 10px;
    content: "";
  }
}

.observe {
  content: "";
  display: flex;
  height: 81px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.text {
  &__size {
    font-size: 18px;
  }
  &__bold {
    font-weight: 700;
  }
  &__thin {
    font-weight: 300;
  }
  &__color {
    &_link {
      color: #2b587a;
    }
    &_green {
      color: #7ea65a;
    }
    &_red {
      color: #d44f30;
    }
  }

  &__opacity {
    opacity: 0.6;
  }
  // TODO rename this
  &__main {
    margin: 0 8px 0 0;
  }

  &__sub {
    // align-self: flex-end;
    font-size: 13px;
  }
}

.arrow {
  margin: 0 5px 5px 0;
}

.animation-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin: 0 0 10px 0;

  height: 40px;
  width: 100%;

  background: #383f48;
  border: 1px solid #383f48;
  box-sizing: border-box;
  border-radius: 10px;

  &__icon {
    position: relative;
    width: 125px;
    height: 125px;
    margin: 50px auto 0;
  }

  &__hidden {
    display: none;
  }
}

.hash-img {
  opacity: 1;
}
.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}
.link-size {
  font-size: 16px;
}
@media screen {
  .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 3px;

    &_align-top {
      justify-content: flex-start;
    }
    &__image {
      width: 20px;
      height: 20px;
    }

    // .panel__text
    // &__text {
    //   width: 90%;
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;

    //   text-overflow: ellipsis;
    //   text-align: left;
    //   display: block;
    //   margin: 5px 0;

    //   font-size: 20px;

    //   &:hover + .panel__toltip, &:hover_effect + .panel__toltip{
    //     display: block;
    //   }
    // }

    &__toltip {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      background: #fff;
      border: 1px solid;
      width: 330px;
      z-index: 10;
      padding: 0px 10px;
      box-sizing: border-box;
      height: 70px;
      overflow: hidden;
      text-align: left;

      // &:hover, &:hover_effect {
      //   display: block;
      // }
    }

    // .panel__info
    &__info {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      height: 10px;
      color: #c5c5c5;
      font-size: 11px;
      text-decoration: none;
    }

    &__inf {
      margin: -4px 0px;
    }

    // .panel__tags
    &__tags {
      font-size: 10px;
      content: "";
    }
  }
}

@media (hover: hover) {
  .table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (max-width: 850px) {
      width: auto;
    }
    // .table__caption

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      border-bottom: 1px solid #dee5eb;

      padding: 0 8px;

      &:hover .cell-settings__eye {
        opacity: 1;
      }
    }
  }

  .cell-settings {
    &__numbers {
      min-width: 50px;
      width: 50px;
      margin: 0 15px 0 0;
    }

    &__public {
      min-width: 250px;
      width: 40%;
      justify-content: flex-start;
    }

    &__coverage {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
    }

    &__subscribes {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
    }

    &__auditory {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
    }

    &__grow {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
      cursor: default;
    }

    &__eye {
      min-width: 60px;
      width: 60px;
      opacity: 0;
    }
  }
}
@media (max-width: 1900px) {
  .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 0 3px;

    // width: 100%;
    &_align-top {
      justify-content: flex-start;
    }
    &__image {
      width: 20px;
      height: 20px;
    }

    // .panel__text
    &__text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      display: block;
      margin: 5px 0;

      font-size: 20px;
      // overflow: hidden;

      // &:hover + .panel__toltip {
      //  display: block;
      // }
    }

    // &__toltip {
    //   display: none;
    //   position: absolute;
    //   top: 0;
    //   left: 100%;
    //   background: #fff;
    //   border: 1px solid;
    //   width: 330px;
    //   z-index: 10;
    //   padding: 0px 10px;
    //   box-sizing: border-box;
    //   height: 70px;
    //   overflow: hidden;
    //   text-align: left;

    //   &:hover {
    //     display: block;
    //   }
    // }

    // .panel__info
    &__info {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      height: 10px;
      color: #c5c5c5;
      font-size: 11px;
      text-decoration: none;
    }

    &__inf {
      margin: -4px 0px;
    }

    // .panel__tags
    &__tags {
      font-size: 10px;
      content: "";
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &__search {
      width: 90%;
      height: 35px;

      font-size: 18px;
      padding: 0 5px 5px;

      &::placeholder {
        font-size: 18px;
        line-height: 32px;
      }
    }

    &__image-size {
      width: 50px;
      height: 50px;

      margin: 0 5px 0 0;
    }

    &__icon {
      width: 50px;
      height: 50px;
    }

    &__arrow {
      transform: rotate(-180deg);
    }

    &__img {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 0 5px 5px;
      //opacity: 0;
    }

    // .table__header
    &__header {
      display: flex;
      background: #dee5eb;

      padding: 0 5px;
    }

    // .table__title
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 60px;

      cursor: pointer;
      color: #45688e;
    }

    // .table__body
    &__body {
      display: flex;
      flex-direction: column;

      border-top: 1px solid #dee5eb;
    }

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      border-bottom: 1px solid #dee5eb;
    }

    // .table__cell
    &__cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      padding: 5px 0 5px;
      margin: 1px 0;
      background: #fff;
    }
  }

  .img-show {
    opacity: 1;
  }
}
@media (max-width: 1600px) {
  .table {
    // .table__cell
    &__cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      padding: 5px 0 5px;
      margin: 1px 0;

      background: #fff;
    }
  }

  .panel {
    // .panel__text
    &__text {
      font-size: 15px;
      width: 100%;
      // &:hover + .panel__toltip{
      //   display: block;
      // }
    }
    // &__toltip {
    //   display: none;
    //   position: absolute;
    //   top: 0;
    //   left: 100%;
    //   background: #fff;
    //   border: 1px solid;
    //   width: 330px;
    //   z-index: 10;
    //   padding: 0px 10px;
    //   box-sizing: border-box;
    //   height: 70px;
    //   overflow: hidden;
    //   text-align: left;

    //   &:hover {
    //     display: block;
    //   }
    // }

    // .panel__info
  }

  .img-show {
    opacity: 1;
  }
}

@media (max-width: 1300px) {
  .table {
    // .table__cell
    &__cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      padding: 5px 0 5px;
      margin: 1px 0;

      background: #fff;
    }
  }

  .contenter {
    width: 100%;
  }

  .panel {
    // width: 100%;
    // width: calc(100% - 70px);
    // .panel__text
    &__text {
      //min-width: 130px;
      width: 100%;

      font-size: 15px;
    }
  }

  .img-show {
    opacity: 1;
  }

  .settings {
    position: absolute;
    top: 90px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: 100%;
  }

  .show {
    visibility: visible;
  }
}

@media (max-width: 1100px) {
  .settings {
    position: absolute;
    top: 90px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: 100%;
  }

  .panel {
    // .panel__text
    &__text {
      width: 100%;
      font-size: 15px;
    }
  }

  .show {
    visibility: visible;
  }

  .img-show {
    opacity: 1;
  }
}
@media (max-width: 850px) {
  .table {
    width: auto;
  }
}

.settings-height {
  height: auto;
  padding: 0 12px 50px 12px;
}

.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}
</style>
