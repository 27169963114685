<template>
  <div
    class="title-tb"
    :class="{ 'wrap-start': !showMainMenu || !isArrow || blockMenu }"
    @click="calsAction('toogleTable', itemPosition)"
  >
    <div class="title-tb__input-panel">
      <arrow-icon
        v-if="isArrow"
        ref="arrow"
        :style="{ transform: 'rotate(' + rotateArrow + 'deg)' }"
        class="title-tb__arrow"
      ></arrow-icon>

      <base-check-box
        v-else
        class="title-tb__checkbox"
        @update:startState="calsAction('updateList', itemPosition)"
        @click.stop
      />

      <div ref="target" class="title-tb__input">
        <slot ref="inputik"> </slot>
      </div>

      <button
        v-if="isArrow"
        :style="{ visibility: showSubmit ? 'visible' : 'hidden' }"
        class="title-tb__button"
        @click.stop="calsAction('submit', itemPosition)"
      >
        <img src="/icons/common/submit.svg" class="title-tb__icon" />
      </button>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/atom/html-icon/arrow/DropDownArrow.vue';
import BaseCheckBox from '../atom/BaseCheckBox.vue';

export default {
  components: {
    ArrowIcon,
    BaseCheckBox,
  },
  props: {
    itemPosition: {
      type: Number,
      default: 0,
    },

    isArrow: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['calsAction'],
  computed: {},
  watch: {
    isArrow(to) {
      if (!to && this.showSubmit) {
        this.toogleShowSubmit(false);
      }
    },
  },
  data() {
    return {
      showSubmit: false,
      rotateArrow: -90,
      showMainMenu: true,
      blockMenu: false,
      // showContainer: false,
    };
  },
  methods: {
    hello() {
      // console.log('hello');
    },
    calsAction(action, value) {
      if (action === 'toogleTable' && this.$refs.arrow !== null) {
        this.rotateArrow = this.rotateArrow !== 0 ? 0 : -90;
        // const rotation = this.rotateArrow''
        // console.log('ARROW', this.rotateArrow, this.$refs.arrow.$el);
        // this.$refs.arrow.$el.style.transform = `rotate(${this.rotateArrow}deg)`;
      }

      if (action === 'edit') {
        this.showSubmit = true;
      }

      if (action === 'submit') {
        // console.log('action === submit');
        this.showSubmit = false;
      }

      if (action === 'select') {
        this.showMainMenu = false;
      }
      // console.log('watch opa');
      this.$emit('calsAction', { action, value });
    },

    toogleShowSubmit(newSubmit) {
      this.showSubmit = newSubmit;
      // console.log('toogleShowSubmit cals');
      this.calsAction('submit', this.itemPosition);
    },

    calsSubAction(action, value) {
      // console.log('watch opa');
      this.showMainMenu = true;
      this.$emit('calsAction', { action, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // .title__input-panel
  &__input-panel {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 50%;
    min-width: 25rem;
    height: 100%;
  }

  // .title__inpu

  // .title__menu
  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto;

    opacity: 1;
  }
  @media (hover: hover) {
    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 auto;

      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__sub-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto;

    opacity: 1;
  }

  &__button {
    width: 32px;
    height: 32px;

    margin: 0 5px;
    border: none;
    cursor: pointer;
  }

  // .title__icon
  &__icon {
    width: 100%;
    height: 100%;
  }

  &__arrow {
    width: 25px;
    height: 25px;

    margin: 0 0 0 5px;

    transform: rotate(-90deg);
  }

  &__checkbox {
    width: 20px;
    height: 20px;

    margin: 0 0 0 5px;
  }
}

.wrap-start {
  justify-content: flex-start;
}
</style>
