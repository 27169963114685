<template>
  <div class="aside-menu">
    <aside-control class="control-menu">
      <template v-slot:title> Меню администратора </template>

      <template v-slot:default>
        <a
          v-for="(button, index) in menuButtons"
          :key="index"
          href="#"
          class="button"
          @click.prevent="setAction(button)"
        >
          {{ button.name }}
        </a>
      </template>
    </aside-control>

    <aside-control class="control-menu">
      <template v-slot:title> Состояние ботов</template>

      <template v-slot:default>
        <textarea ref="textarea" class="control-menu__info" readonly v-model="logMessage">
        </textarea>

        <a href="#" class="button" @click.prevent="updateInfo()"> Обновить статус </a>
      </template>
    </aside-control>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AsideControl from '@/components/common/teamplates/ToogleMenu.vue';

export default {
  components: {
    AsideControl,
  },
  inject: ['currentlass'],
  emits: ['action'],
  computed: mapGetters('vkRequests', ['getGroupCount']),
  watch: {
    currentlass: {
      handler() {
        this.currentlass.value.getTokenList().then((resp) => {
          const [firstEl] = resp;
          this.logMessage = firstEl;
        });
      },
      deep: true,
    },
  },
  data: () => ({
    showMenu: true,
    showFilter: true,
    logMessage: '',

    menuButtons: [{ name: 'Черный список', action: 'black-list', route: 'edit-page' }, { name: 'Продажа пабликов', action: 'sale-page', route: 'sale-page' }],
  }),
  methods: {
    setAction(button) {
      this.$emit('action', button.action);
      this.$router.push({ name: button.route });
    },
    updateInfo() {
      this.currentlass.value.getTokenList().then((resp) => {
        const [firstEl] = resp;
        this.logMessage += `\n${firstEl}`;

        this.$nextTick(() => {
          this.$refs.textarea.scrollTop = this.$refs.textarea.scrollHeight;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.static-info {
  color: #45688e;
  font-weight: 700;
  font-size: 18px;

  margin: 18px 0 20px 0;
}
.control-menu {
  margin: 0 0 20px 0;

  &__info {
    width: 100%;
    height: 200px;

    margin: 20px 0 0 0;
    border-radius: 20px;
    padding: 10px 12px 10px;
    box-sizing: border-box;

    // overflow: auto;

    resize: none;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: aliceblue;

  border-radius: 10px;
  background: #45688e;

  margin: 15px 10px;
}
</style>
