import userGroups from '@/api/userGroups';

export default class UserListPannel {
  groupList = []

  userId = -1

  constructor(userId, listGroup) {
    if (Number.isNaN(Number(userId))) {
      return;
    }

    this.userId = userId;
    this.updateGroupList(listGroup);
  }

  loadLists() {
    userGroups.getGroupList(this.userId).then((res) => {
      if (res.error) {
        return;
      }

      this.updateGroupList(res);
    });
  }

  updateGroupList(requestResult) {
    const teamplates = requestResult;
    teamplates.forEach((el, index) => {
      teamplates[index].isOpen = false;
      teamplates[index].readonly = true;
      teamplates[index].id = el.list_id;
      teamplates[index].title = el.list_name;
      teamplates[index].table = [];
      teamplates[index].isSelectShow = false;
      teamplates[index].showAnimation = false;
    });

    this.groupList = teamplates;
  }

  getGroupList() {
    return this.groupList;
  }
}
