<template>
  <div class="admin-page">
    <div class="admin-page__admins">
      <user-list v-for="(user, index) in userList" :key="index" :userInfo="user">
        <template #user>
          <img :src="user.photo_link" class="admin-page__icon" />

          {{ user.first_name + " " + user.last_name }}
        </template>
      </user-list>
    </div>

    <aside-menu class="settings" :class="{ show: getSettingsState }"></aside-menu>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapGetters } from 'vuex';
import AsideMenu from '@/components/admin-page/AsideMenu.vue';
import UserList from '@/components/admin-page/UsersList.vue';
import AdminAPI from '@/api/adminAPI';

export default {
  components: {
    AsideMenu,
    UserList,
  },

  computed: {
    ...mapGetters('vkConfiguration', ['getSettingsState']),
  },

  data() {
    return {
      adminAPI: {},
      userList: [],
    };
  },

  provide() {
    return {
      currentlass: computed(() => this.adminAPI),
    };
  },

  async mounted() {
    this.adminAPI = new AdminAPI();

    this.adminAPI.getGroupList().then((resp) => {
      this.userList = resp;
    });
  },
};
</script>

<style lang="scss" scoped>
.admin-page {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;

  width: 100%;

  &__admins {
    width: calc(100% - 350px);
    border: 1px solid #03030325;
    position: relative;
    font-size: 22px;

    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  &__icon {
    width: 75px;
    height: 75px;
    border-radius: 20px;

    margin: 0 25px 0 0;
  }
}

.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;

  @media (max-width: 1300px) {
    position: absolute;
    top: 1px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: auto;
    min-height: 100%;
    padding: 10px 0 0 0;
  }
}

.show {
  visibility: visible;
}
</style>
