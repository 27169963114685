<template>
  <div class="user-list">
    <div class="user-list__title" @click="toogleContainerVisible">
      <arrow-icon
        ref="arrow"
        :style="{ transform: 'rotate(' + rotateArrow + 'deg)' }"
        class="user-list__arrow"
      ></arrow-icon>

      <slot name="user"></slot>
    </div>

    <div v-if="showList" ref="list" class="user-list__container">
      <table-teamplate
        :ref="'tmper' + index"
        v-for="(tmp, index) in getGroupList"
        :key="tmp.id"
        :showContainer="tmp.isOpen"
        class="item-list"
      >
        <template #title>
          <table-teamplate-title :isArrow="isArrow" :itemPosition="index" @calsAction="calsAction">
            <div class="tester">
              <div class="contain">
                <input
                  :ref="'tmp' + index"
                  type="text"
                  class="title__input"
                  v-model="tmp.title"
                  :readonly="tmp.readonly"
                  @click.prevent
                  onmousedown="return false"
                />
              </div>

              <pagination
                v-if="tmp.isOpen"
                ref="pagination"
                class="title__caption"
                :tableName="tmp.title"
                :target="target"
                :indexPosition="
                  paginationgetClassInstanceList[tmp.title]?.getPaginstionState() ?? 0
                "
                @updatedTable="updatedTable(tmp.title + index, $event)"
              ></pagination>
            </div>
          </table-teamplate-title>
        </template>

        <template #table>
          <table-data-user
            :ref="tmp.title + index"
            v-if="tmp.isOpen"
            :table="tmp.table"
            :showAnimation="tmp.showAnimation"
            :isMainGroup="tmp.title === 'Мои группы'"
            :indexPosition="paginationgetClassInstanceList[tmp.title]?.getPaginstionState() ?? 0"
            :tableName="tmp.title || ''"
            :isSelectShow="tmp.isSelectShow"
            :isActionByUpdate="tmp.title === 'Мои группы'"
            @toogleInfoPage="toogleInfoPage"
            @sellGroup="setGroupForSell"
            @dellGroup="prepareGroupForDelete"
          ></table-data-user>
        </template>
      </table-teamplate>
    </div>

    <teleport to="body">
      <transition name="fade">
        <group-statistic
          v-if="isOpenGroupInformation"
          :groupInfo="groupInfo"
          :tableName="tableName"
          :groupListPosition="groupListPosition"
          @closeWindow="toogleInfoPage"
          class="group-statistic"
        ></group-statistic>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { computed } from 'vue';
import TableTeamplate from '@/components/admin-page/TableTeamplate.vue';
import TableTeamplateTitle from '@/components/admin-page/TableTeamplateTitle.vue';
import TableDataUser from '@/components/admin-page/DataUserTable.vue';
import RequestApi from '@/api/vk';
import userGroups from '@/api/userGroups';
import PublicSale from '@/api/publicSale';
import ArrowIcon from '@/components/atom/html-icon/arrow/DropDownArrow.vue';
import UserListPanel from '@/utils/userListPanel';
import GroupStatistic from '@/components/common/GroupStatistic.vue';
import Pagination from '@/components/admin-page/DataUserPaginationTest.vue';

export default {
  props: {
    showContainer: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  provide() {
    return {
      classInstanceList: computed(() => this.classInstanceList),
    };
  },
  components: {
    TableTeamplate,
    TableTeamplateTitle,
    TableDataUser,
    ArrowIcon,
    GroupStatistic,
    Pagination,
  },
  data() {
    return {
      classInstanceList: {},
      getGroupList: [],
      userList: {},
      showList: false,
      rotateArrow: -90,
      // check
      tasksListElement: '',
      taskElements: '',
      currentElement: '',
      teamplates: [],
      enableEye: false,
      tableName: '',
      groupListPosition: '',

      loadCount: false,
      show: false,
      addClassToPagination: false,
      delbuf: '',
      groupInfo: {},

      searchText: '',
      rotate: 0,
      listShow: null,
      selectedRows: [],
      showCatalogs: true,
      options: {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
      target: null,
      observer: null,

      isOpenGroupInformation: false,
      // hash map
      names: {
        Подписчики: 'sub',
        Посетители: 'vis',
        Прирост: 'grow',
        Охват: 'cov',
      },
      showConfirmWindow: false,
      valueConfirmWindow: -1,

      classTables: {},
      showTransferMenu: false,
      showTransfer: false,

      currentSaleGroup: {},
      showSellGroupModal: false,

      isArrow: true,
      groups: [],

      showConfirmForDel: false,
      deletedGroup: {},
      publicSale: {},
    };
  },
  computed: {
    ...mapGetters('vkConfiguration', ['getSettingsState']),
    ...mapGetters('adminRequest', ['checkLoginState', 'isUpdateVK']),
    // ...mapGetters('userPanel', ['getGroupList']),
    ...mapGetters('pagination', ['getClassInstanceList']),

    paginationgetClassInstanceList() {
      return this.classInstanceList;
    },
  },

  methods: {
    ...mapActions('vkRequests', [
      'requestCommunities',
      'requestByDate',
      'requestByType',
      'requestByStatus',
      'requestAllStaticParams',
      'requestCategory',
      'requestByVerification',
      'requestBySort',
      'requestBySearch',
      'requestPage',
      'requestPagePagination',
      'cancleRequest',
    ]),
    ...mapActions('pagination', ['addNewInstance']),
    ...mapMutations('vkRequests', [
      'setCurrentDate',
      'setLoadPage',
      'setChangeAnotherPage',
      'resetJSON',
      'setSearchInput',
      'resetAllParams',
      'setFiltredParametr',
      'setAdminsId',
    ]),
    ...mapMutations('adminRequest', ['dropVKUpdate']),
    ...mapMutations('routerControl', ['setCurrentActivePage']),
    ...mapMutations('userPanel', ['resetGroups']),

    paginationaddNewInstance(newClass) {
      this.classInstanceList = {
        ...this.classInstanceList,
        ...newClass,
      };
    },

    toogleContainerVisible() {
      if (this.rotateArrow === 0) {
        this.getGroupList.forEach((el, ind) => {
          this.getGroupList[ind].isOpen = false;
        });
      }

      this.showList = !this.showList;
      this.rotateArrow = this.rotateArrow !== 0 ? 0 : -90;
    },

    dragElement(value) {
      this.currentElement = this.$refs[value].$el;
    },

    getNextElement(cursorPosition, currentElement) {
      const currentElementCoord = currentElement.getBoundingClientRect();
      const currentElementCenter = currentElementCoord.y + currentElementCoord.height / 2;

      const { nextElementSibling } = currentElement;
      const nextEl = cursorPosition < currentElementCenter ? currentElement : nextElementSibling;

      return nextEl;
    },

    createNewTeamplate(titleName) {
      this.showListNameWindow = false;

      const newList = {
        id: this.getGroupList.length,
        title: titleName,
        isOpen: false,
        readonly: true,
      };

      this.$store.dispatch('userPanel/create', newList);
    },

    async calsAction({ action, value }) {
      if (action === 'toogleTable') {
        // console.log('cals toogleTable');
        const { isOpen } = this.getGroupList[value];

        this.getGroupList[value].isOpen = !isOpen;

        if (this.getGroupList[value].length !== 0) {
          const name = this.getGroupList[value].title ?? 'lol';
          const listId = this.getGroupList[value].id;

          if (this.paginationgetClassInstanceList[name] === undefined) {
            const newClass = { [name]: new RequestApi(listId) };

            this.paginationaddNewInstance(newClass);
            this.getGroupList[value].showAnimation = true;

            await newClass[name].requestAuditory();

            newClass[name]
              .getGroupsJSON()
              .then((resp) => {
                this.getGroupList[value].table = resp;
                this.getGroupList[value].showAnimation = false;
              })
              .catch(() => {
                this.getGroupList[value].showAnimation = false;
              });
          }
        }
        return;
      }

      if (action === 'submit') {
        this.$store
          .dispatch('userPanel/update', { index: value })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });

        const updateArr = { index: value, param: 'readonly', state: true };
        this.$store.dispatch('userPanel/updateParam', updateArr);

        this.$refs[`tmp${value}`].value += ' ';
        this.$refs[`tmp${value}`].value = this.$refs[`tmp${value}`].value.slice(0, -1);
        this.$refs[`tmp${value}`].blur();
        this.$refs[`tmp${value}`].onmousedown = () => false;
        this.$refs[`tmp${value}`].onclick = null;

        return;
      }

      if (action === 'edit') {
        // console.log('before focus', this.teamplates[value]);

        const updateArr = { index: value, param: 'readonly', state: false };
        this.$store.dispatch('userPanel/updateParam', updateArr);

        this.$refs[`tmp${value}`].onmousedown = () => true;
        this.$refs[`tmp${value}`].onclick = (event) => {
          if (event.stopPropagation) {
            event.stopPropagation();
          } else if (window.event) {
            window.event.cancelBubble = true;
          }
        }; /*  */
        // this.$refs[`tmp${value}`].style['pointer-events'] = 'auto';
        this.$refs[`tmp${value}`].focus();
        this.$refs[`tmp${value}`].select();
        return;
      }

      if (action === 'select' || action === 'selectClose') {
        this.getGroupList[value].isSelectShow = !this.getGroupList[value].isSelectShow;
        return;
      }

      if (action === 'deleteGroup') {
        const { title, id: lid } = this.getGroupList[value];
        const delereGroups = this.$refs[`${title}${value}`].getCheckBoxes();

        let deleteGids = '';
        delereGroups.forEach((el) => {
          deleteGids += `${el.id};`;
        });

        userGroups.deleteGroupFromList({ gid: deleteGids, lid }).then(() => {
          const arrGid = deleteGids.split(';');
          arrGid.forEach((el) => {
            const group = this.findGroupIndexByID(el, this.getGroupList[value]);

            if (+group === -1 || el === '') {
              return;
            }

            this.getGroupList[value].table.splice(group, 1);
            // delete this[delFrom][el];
          });
        });

        this.getGroupList[value].isSelectShow = !this.getGroupList[value].isSelectShow;
        return;
      }

      if (action === 'updateList') {
        this.toogleTransferMenu(value);

        this.checkList('transfer');
        return;
      }
      // console.log('this.getGroupList[value]', this.getGroupList[value]);
      if (action === 'delete' && this.getGroupList[value].type !== 'common') {
        alert('Вы не можете удалить данную группу');
        return;
      }
      this.showConfirmWindow = true;
      this.valueConfirmWindow = value;
    },

    findGroupIndexByID(id, currentGroup) {
      return currentGroup.table.findIndex((el) => +el.id === +id);
    },

    toogleTransferMenu(groupIndex) {
      // console.log('cals toogleTransferMenu');
      const isShow = this.passToTransferArrayData(groupIndex);
      if (!isShow) {
        return;
      }

      this.showTransferMenu = !this.showTransferMenu;
      // console.log(this.getGroupList, groupIndex);
    },

    passToTransferArrayData(groupIndex) {
      const selectedGroup = this.getGroupList[groupIndex];
      const findFavorites = this.findGroupByName(this.getGroupList, 'Избранное');

      // TODO add check by admins group
      if (selectedGroup.type !== 'common') {
        // selectedGroup.title === 'Мои группы'
        alert('Выберете иную группу');
        return false;
      }

      this.groups.push(this.getGroupList[groupIndex]);

      if (findFavorites) {
        this.groups.push(findFavorites);
      }

      return true;
    },

    findGroupByName(groups, groupName) {
      const result = groups.find((el) => el.title === groupName);
      // console.log('findGroupByName', result);

      return result;
    },

    setGroupForSell(item = null) {
      this.showSellGroupModal = !this.showSellGroupModal;
      if (item !== null) {
        this.currentSaleGroup = item;
      }
    },

    updateSellGroupOnClose(isUpdated) {
      if (isUpdated) {
        this.currentSaleGroup.isSale = !this.currentSaleGroup.isSale;
      }
      this.showSellGroupModal = !this.showSellGroupModal;
    },

    prepareGroupForDelete(item = null) {
      if (item !== null) {
        this.showConfirmForDel = true;
        this.deletedGroup = item;
        // console.log('prepareGroupForDelete', item);
      }
    },
    deleteGroupByConfirm() {
      this.publicSale
        .deleteGroupBySale({
          gid: this.deletedGroup.id,
          uid: this.$store.getters['auth/authParams'].userId,
        })
        .then((resp) => {
          // console.log('deleteGroupByConfirm', resp);
          if (resp.message === 'access') {
            this.showConfirmForDel = false;
            this.deletedGroup.isSale = false;
          }
        })
        .catch(() => {
          alert('CAN NOT DELETE GROUP');
        });
    },

    findGroupIndexByName(name, currentGroup) {
      // console.log('sell group', name, currentGroup);
      return currentGroup.findIndex((el) => el.list_name === name);
    },

    actionByConfirm(confirmState) {
      if (!confirmState) {
        this.valueConfirmWindow = -1;
        return;
      }

      this.showConfirmWindow = false;

      this.$store
        .dispatch('userPanel/delete', this.valueConfirmWindow)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.valueConfirmWindow = -1;
        });
    },

    toogleInfoPage({
      groupInfo, isShow, tableName, groupListPosition,
    }) {
      if (!isShow) {
        this.groupInfo = {};
        this.tableName = '';
        this.groupListPosition = '';
      } else {
        this.groupInfo = groupInfo;
        this.tableName = tableName;
        this.groupListPosition = groupListPosition;
      }

      this.isOpenGroupInformation = isShow;
    },

    // TODO check for dell
    calcWidth(item) {
      // console.log(item);
      const width = +item.verified === 1 || +item.closed === 1 ? 20 : 0;
      return `calc(100% - ${width}px)`;
    },
    // not requests

    setArrow(item) {
      return +item.grow_count >= 0 ? 'arrow-up' : 'arrow-down';
    },

    callFromFilterRequest(groupName, settingName) {
      switch (true) {
        case groupName === 'period':
          this.requestByDate(settingName);
          break;
        case groupName === 'type':
          this.requestByType(settingName);

          if (settingName === 'group') {
            this.showCatalogs = false;
          } else {
            this.showCatalogs = true;
          }
          break;
        case groupName === 'status':
          this.requestByStatus(settingName);

          break;
        default:
      }
    },
    updatedTable(refer, event) {
      this.$refs[refer].updatedTable(event.animation, event.result);

      // console.log('tmp', tmp);
      // const findIndex = this.getGroupList.findIndex((el) => el.id === tmp.id);
      // if (findIndex !== -1) {
      //   const name = this.getGroupList[findIndex].title ?? 'lol';
      //   this.paginationgetClassInstanceList[name].getGroupsJSON()
      //     .then((resp) => {
      //       this.getGroupList[findIndex].table = resp;
      //       this.getGroupList[findIndex].showAnimation = false;
      //     })
      //     .catch(() => {
      //       this.getGroupList[findIndex].showAnimation = false;
      //     });
      // }
    },
  },
  async mounted() {
    // this.tasksListElement = this.$refs.list;
    // this.taskElements = this.tasksListElement.querySelectorAll('.item-list');

    this.$store.dispatch('userPanel/load');
    this.publicSale = new PublicSale();
    this.userList = new UserListPanel(11, this.userInfo.lists);
    this.getGroupList = this.userList.getGroupList();
  },
  unmounted() {
    this.resetGroups();
  },
};
</script>

<style lang="scss" scoped>
.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}

.contain {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;

  &__caption {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    margin: 0 5px 0 0;
    position: absolute;
    top: 7px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    right: 0;
  }

  // .title__input-panel
  &__input-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }

  // .title__input
  &__input {
    width: 100%;
    height: 32px;
    // user-select: none;
    // pointer-events: none;
    margin: 0 0 0 10px;

    border: none;
    cursor: default;

    font-size: 18px;
    padding: 0 5px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    outline: none;

    background: #c5c5c500;
  }

  // .title__menu
  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto;

    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  &__button {
    width: 40px;
    height: 40px;

    margin: 0 5px;
  }

  // .title__icon
  &__icon {
    width: 100%;
    height: 100%;
  }

  &__arrow {
    width: 25px;
    height: 25px;

    transform: rotate(-90deg);
  }
}
.communites {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;

  width: 100%;
}

.contenter {
  width: calc(100% - 350px);
  @media (max-width: 1300px) {
    width: 100%;
  }
}

.teamplate {
  border: 1px solid #03030325;
  position: relative;
}

.show {
  visibility: visible;
}

// uses

.user-list {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;

  // .tbl-teamplate__title
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: auto;

    background: #f2f3f5;
    border-bottom: 1px solid #03030325;
    padding: 5px 0;
  }

  // .tbl-teamplate__container
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #727ea317;

    overflow: auto;

    width: 100%;
    height: 50vh;

    // padding: 0 0 0 45px;
  }

  &__arrow {
    width: 25px;
    height: 25px;

    margin: 0 10px 0 5px;

    transform: rotate(-90deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.tester {
  display: flex;
  width: 100%;
}
</style>
