<template>
  <div class="tbl-teamplate">
    <div href="#" class="tbl-teamplate__title">
        <slot name="title"></slot>
    </div>

    <div v-if="showContainer" :draggable="false" class="tbl-teamplate__container">
        <slot name="table"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showContainer: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tbl-teamplate {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;

  // .tbl-teamplate__title
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: calc(100% - 41px);
    height: 40px;

    background: #f2f3f5;
    border-bottom: 1px solid #03030325;
    padding: 0 0 0 41px;

    position: sticky;
    z-index: 100;
    top: 0;

  }

  // .tbl-teamplate__container
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffffbb;

    overflow: auto;

    width: 100%;
    height: 100%;
    min-height: 20vh;
  }
}
</style>
