<template>
  <svg
    viewBox="0 0 12 8"
    fill="none"
    class="container"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.0001 7.40001L0.600098 2.00001L2.0001 0.600006L6.0001
      4.60001L10.0001 0.600006L11.4001 2.00001L6.0001 7.40001Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#C5C5C5',
    },
  },
};
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
}
</style>
