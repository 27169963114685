<template>
  <div class="container-control">
    <a class="container-control__button" @click="showContainer = !showContainer"
      ><slot class="container-control__title" name="title">
        Menu
        </slot
    ></a>

    <div v-if="showContainer" class="container-control__container">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({ showContainer: true }),
};
</script>

<style lang="scss" scoped>
.container-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  // .container-control__button

  &__button {
    margin: 0 10px;
    padding: 0 8px;

    box-sizing: border-box;
    border: 1px solid transparent;

    cursor: pointer;
    background: #dbdcdd;
    width: 100%;
    height: auto;
    padding: 10px;
    user-select: none;
  }

  // .container-control__container

  &__container {
    width: 100%;
    height: 100%;
  }
}
</style>
