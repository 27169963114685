<template>
  <div class="container">
    <label :style="setCheckBoxColor" class="check-box">
      <input
        type="checkbox"
        class="check-box__input"
        ref="check-box"
        :checked="startState"
        @change="$emit('update:startState', $event.target.checked)"
      />

      <span class="check-box__content"> </span>
    </label>
  </div>
</template>

<script>
export default {
  emits: ['update:startState'],
  props: {
    startState: {
      type: Boolean,
      default: false,
    },

    activeCheckBoxColor: {
      type: String,
      default: '#5e82a8',
    },
    checkBoxColor: {
      type: String,
      default: '#454c52',
    },
  },
  computed: {
    setCheckBoxColor() {
      return {
        '--base-active-check-box': this.activeCheckBoxColor,
        '--base-check-box': this.checkBoxColor,
      };
    },
  },
  methods: {
    setCheckedState(value = false) {
      this.$refs['check-box'].checked = value;
    },

    publicClick() {
      this.$refs['check-box'].click();
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "/src/assets/css/mixins.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-box {
  display: block;

  width: 100%;
  height: 100%;

  &__input {
    display: none;

    &:checked + .check-box__content::after {
      opacity: 1;
    }
    &:checked + .check-box__content::before {
      border: 2px solid var(--base-active-check-box);
    }
  }

  // mb for del
  &__content {
    display: block;

    position: relative;

    width: 100%;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;

      //width: 15px;
      //height: 15px;
      width: 100%;
      height: 100%;

      border: 2px solid var(--base-check-box);
      box-sizing: border-box;
      border-radius: 4px;
    }

    &::after {
      content: "";
      position: absolute;

      width: 50%;
      height: 50%;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background: var(--base-active-check-box);
      opacity: 0;
    }
  }
}
</style>
