<template>
  <points-line v-if="animation" :class="{ back: addClassToPagination }" class="animation" />

  <div v-else-if="getMaxButtons !== 0" :class="{ back: addClassToPagination }" class="pagination">
    <a
      v-if="!checkFirstButton"
      href="#"
      class="pagination__button"
      @click.stop="setFirstOrLastIndex(true)"
    >
      <img src="/icons/pagination/arrow-double.svg" class="pagination__icon" />
    </a>

    <a
      v-for="(button, index) in buttons"
      href="#"
      :key="index"
      @click.stop="checkPage(button)"
      :class="{ 'active-button': button.index === currentButton }"
      class="pagination__button"
    >
      <span v-if="button.index >= 1 && button.index <= this.getMaxButtons">{{ button.index }}</span>
    </a>

    <a
      href="#"
      v-if="checkLastButton"
      class="pagination__button"
      @click.stop="setFirstOrLastIndex(false)"
    >
      <img
        src="/icons/pagination/arrow-double.svg"
        class="pagination__icon pagination__icon_last-arrow"
      />
    </a>
  </div>
</template>

<script>
import PointsLine from '@/components/atom/loader/PointsLine.vue';

export default {
  props: {
    target: {
      type: Object,
      default() {
        return {};
      },
    },
    tableName: {
      type: String,
      default: '',
    },
    indexPosition: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PointsLine,
  },
  inject: ['classInstanceList'],
  computed: {
    checkFirstButton() {
      return this.buttons[0].index === this.currentButton;
    },

    checkLastButton() {
      return this.getMaxButtons !== this.currentButton;
    },
  },
  watch: {
    target(to) {
      if (to !== null && this.observer !== null) {
        this.observer.observe(this.target);
      }
    },
  },
  data() {
    return {
      observer: null,
      currentButton: 1,
      addClassToPagination: false,
      buttons: [
        {
          name: 'buttonA',
          index: 1,
        },
        {
          name: 'buttonB',
          index: 2,
        },
        {
          name: 'buttonC',
          index: 3,
        },
      ],
      getMaxButtons: 0,
      animation: false,
    };
  },
  methods: {
    updateMaxButtons(newCount) {
      this.getMaxButtons = newCount;
    },

    checkPage(buttonItem) {
      let increment = 0;
      let addNewIndex = false;

      switch (true) {
        case buttonItem.index === this.currentButton:
          return;
        case this.getMaxButtons <= 3:
          increment = buttonItem.index - this.currentButton;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment);
          return;
        case buttonItem.name === 'buttonA':
          increment = buttonItem.index - this.currentButton;
          addNewIndex = buttonItem.index !== 1;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment, addNewIndex);
          return;

        case buttonItem.name === 'buttonB':
          increment = buttonItem.index - this.currentButton;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment, false);
          break;
        case buttonItem.name === 'buttonC':
          increment = buttonItem.index - this.currentButton;
          addNewIndex = buttonItem.index !== this.getMaxButtons;
          this.currentButton = buttonItem.index;
          this.requestNextPage(increment, addNewIndex);
          break;
        default:
      }
    },

    setFirstOrLastIndex(isFirst = false) {
      this.currentButton = isFirst ? 1 : this.getMaxButtons;

      // document.getElementById('header').scrollIntoView(true);

      this.buttons.forEach((element, ind) => {
        if (isFirst) {
          this.buttons[ind].index = ind + 1;

          this.requestPage(-10);
          return;
        }

        this.buttons[this.buttons.length - ind - 1].index = this.getMaxButtons - ind;

        this.requestPage(10);
      });
    },

    requestNextPage(setCount, newIndex = false) {
      if (newIndex) {
        const updated = setCount > 0 ? 1 : -1;
        for (let i = 0; i < this.buttons.length; i += 1) {
          this.buttons[i].index += updated;
        }
      }

      this.requestPage(setCount);
    },

    requestPage(increment = 0) {
      this.animation = true;
      this.$emit('updatedTable', { animation: true, result: null });
      const requestAPI = this.classInstanceList.value[this.tableName];

      if (requestAPI) {
        requestAPI
          .updatePageByPagination(increment)
          .then((resp) => {
            // this.$store.dispatch('userPanel/updateGroup',
            // { table: resp, tableName: this.tableName });
            this.$emit('updatedTable', { animation: false, result: resp });
            this.animation = false;
          })
          .catch((err) => {
            console.log('catch in update then it work', err);
            this.$emit('updatedTable', { animation: false, result: [] });
            this.animation = false;
          });
      }
    },
  },

  mounted() {
    this.classInstanceList.value[this.tableName].setCallbackFunc(this.updateMaxButtons);

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.addClassToPagination = false;
      }
      if (!entry.isIntersecting) {
        this.addClassToPagination = true;
      }
    }, options);
  },

  unmounted() {
    this.observer.disconnect();
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  /* .pagination__button */
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border-bottom: 4px solid transparent;

    text-decoration: none;
    color: #000000;

    height: 30px;

    cursor: pointer;
  }

  &__icon {
    width: 15px;
    height: 15px;

    &_last-arrow {
      transform: rotate(180deg);
    }
  }
}

.active-button {
  border-bottom: 4px solid #2b587a;
  border-radius: 0;
  color: #2b587a;
}

.animation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.back {
  background: #127ddb29;
}
</style>
